import $ from 'jquery';

$.extend({
	// Return the size of an object or array
	sizeOf: function(obj) {
		var i = 0;
		for (var key in obj) {
			i++;
		}
		return i;
	},
	// Bootstrap variables
	bootstrap: {
		// Bootstrap 4 Grid breakpoints
		grid_breakpoints: {
			xxs: 0,
			xs: 576,
			sm: 768,
			md: 992,
			lg: 1200,
			xl: 1368
		},
		// Bootstrap 4 Grid containers
		container_max_widths: {
			xs: 576,
			sm: 768,
			md: 992,
			lg: 1200,
			xl: 1368
		}
	}
});

$.fn.extend({
	// Convert the form element's into an object
	serializeObject: function() {
		var o = {};
		var a = this.serializeArray();
		$.each(a, function() {
			if (o[this.name]) {
				if (!o[this.name].push) {
					o[this.name] = [o[this.name]];
				}
				o[this.name].push(this.value || '');
			} else {
				o[this.name] = this.value || '';
			}
		});
		return o;
	},
	// Reset the appearance of the form after the client side validation in Bootstrap 4
	resetAppearanceClientSideValidation: function() {
        $(this).removeClass('was-validated');
        return $(this);
    },
	// Reset the appearance of the form after the server side validation in Bootstrap 4
	resetAppearanceServerSideValidation: function() {
        $(this).find('[name]').removeClass('is-valid').removeClass('is-invalid');
        return $(this);
    }
});

export default $;
